
import { useGlobalContentStore } from '~/store/globalContent';

export default function useStringExtensions() {

    const host = useGlobalContentStore().host;

    function endsWithFileExtension(str: String) {
        const fileExtensions = ['.jpg', '.png', '.pdf', 'mp4'];

        for (const extension of fileExtensions) {
            if (str.endsWith(extension)) {
                return true;
            }
        }

        return false;
    }

    function convertToRelativeUrl(str: string) {
        if(str && !endsWithFileExtension(str) && str?.startsWith(host)) {
            str = str?.substring(host.length, str?.length);
        }
        return str;
    }

    return {
        convertToRelativeUrl
      };
}